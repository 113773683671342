const actionTypes = {
  // CUSTOMER LOGIN ACTIONS
  AUTH_FAIL: 'AUTH_FAIL',
  AUTH_IS_AUTHENTICATED: 'AUTH_IS_AUTHENTICATED',
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  AUTH_RESET: 'AUTH_RESET',
  AUTH_START: 'AUTH_START',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  // CUSTOMER FAVORITES ACTIONS
  CUSTOMER_ADD_TO_FAVORITES: 'CUSTOMER_ADD_TO_FAVORITES',
  CUSTOMER_CLEAR_FAVORITES: 'CUSTOMER_CLEAR_FAVORITES',
  CUSTOMER_EMPTY_TEMP_CART: 'CUSTOMER_EMPTY_TEMP_CART',
  CUSTOMER_REMOVE_FAVORITE: 'CUSTOMER_REMOVE_FAVORITE',
  CUSTOMER_SET_CART: 'CUSTOMER_SET_CART',
  CUSTOMER_SYNC_FAVORITES: 'CUSTOMER_SYNC_FAVORITES',
  CUSTOMER_UPDATE_FAVORITES: 'CUSTOMER_UPDATE_FAVORITES',
  // CART ACTIONS
  CART_ADD_MULTIPLE_PRODUCTS: 'CART_ADD_MULTIPLE_PRODUCTS',
  CART_ADD_PRODUCT: 'CART_ADD_PRODUCT',
  CART_CHANGE_PRODUCT_QUANTITY: 'CART_CHANGE_PRODUCT_QUANTITY',
  CART_REMOVE_PRODUCT: 'CART_REMOVE_PRODUCT',
  CART_RESET: 'CART_RESET',
  // CATALOG ACTIONS
  CATALOG_CHANGE_PAGE: 'CATALOG_CHANGE_PAGE',
  CATALOG_CHANGE_PAGE_SIZE: 'CATALOG_CHANGE_PAGE_SIZE',
  CATALOG_FETCH_PRODUCTS_FAIL: 'CATALOG_FETCH_PRODUCTS_FAIL',
  CATALOG_FETCH_PRODUCTS_INIT: 'CATALOG_FETCH_PRODUCTS_INIT',
  CATALOG_RESET: 'CATALOG_RESET',
  CATALOG_RESET_PAGE: 'CATALOG_RESET_PAGE',
  CATALOG_RESET_RANGE: 'CATALOG_RESET_RANGE',
  CATALOG_SET_BRANDS: 'CATALOG_SET_BRANDS',
  CATALOG_SET_PRODUCTS: 'CATALOG_SET_PRODUCTS',
  CATALOG_CHANGE_SORT_ORDER: 'CATALOG_CHANGE_SORT_ORDER',
  CATALOG_SET_SEARCH_STRING: 'CATALOG_SEARCH_STRING',
  CATALOG_SET_TAGS: 'CATALOG_SET_TAGS',
  CATALOG_UPDATE_REDRAW_KEY: 'CATALOG_UPDATE_REDRAW_KEY',
  // FILTERS
  FILTERS_RESET_ALL: 'FILTERS_RESET_ALL',
  FILTERS_RESET_ATTRIBUTES: 'FILTERS_RESET_ATTRIBUTES',
  FILTERS_RESET_BRANDS: 'FILTERS_RESET_BRANDS',
  FILTERS_RESET_OPTIONS: 'FILTERS_RESET_OPTIONS',
  FILTERS_RESET_RANGE: 'FILTERS_RESET_RANGE',
  FILTERS_RESET_TAGS: 'FILTERS_RESET_TAGS',
  FILTERS_SET_ATTRIBUTES: 'FILTERS_SET_ATTRIBUTES',
  FILTERS_SET_BRANDS: 'FILTERS_SET_BRANDS',
  FILTERS_SET_OPTIONS: 'FILTERS_SET_OPTIONS',
  FILTERS_SET_RANGE: 'FILTERS_SET_RANGE',
  FILTERS_SET_TAGS: 'FILTERS_SET_TAGS',
  // STORE ACTIONS
  STORE_SET_SETTINGS: 'STORE_SET_SETTINGS',
  STORE_SET_STORES: 'STORE_SET_STORES',
  // SEARCH ACTIONS
  SEARCH_RESET_SEARCH_CONTAINER: 'SEARCH_RESET_SEARCH_CONTAINER',
  SEARCH_RESET_SEARCH_TEXT: 'SEARCH_RESET_SEARCH_TEXT',
  SEARCH_SET_SEARCH_CONTAINER: 'SEARCH_SET_SEARCH_CONTAINER',
  SEARCH_SET_SEARCH_TEXT: 'SEARCH_SET_SEARCH_TEXT',
  SEARCH_START_CLICK_LOADING: 'SEARCH_START_CLICK_LOADING',
  SEARCH_START_LOADING: 'SEARCH_START_LOADING',
  SEARCH_STOP_CLICK_LOADING: 'SEARCH_STOP_CLICK_LOADING',
  SEARCH_STOP_LOADING: 'SEARCH_STOP_LOADING',
  ORDER_SET: 'ORDER_SET'
};

export default actionTypes;
